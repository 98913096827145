import { Commit, Dispatch } from "vuex";
import { RootState, User } from ".";
import { getUi } from "./loginui";
import { Link, MarkerType, ShowIcons, MapService, Marker } from "./mapService";

export default {
  // GENERAL STATE
  clearCurrentMap({ commit }: { commit: Commit }) {
    commit("CLEAR_CURRENT_MAP");
  },
  initFirebaseUi({ commit, state }: { commit: Commit; state: RootState }) {
    commit("SET_FIREBASE_UI", getUi(state.firebaseUi));
  },
  clearToast({ commit }: { commit: Commit }) {
    commit("CLEAR_TOAST");
  },
  // USER
  async userChange(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    user: User
  ) {
    commit("CHANGE_USER", user);

    if (state.user) {
      commit("SET_MAPSERVICE", new MapService(state.user));
      if (state.mapService) {
        const {
          creationDate,
          lastLoggedIn
        } = await state.mapService.getUserMetaData();
        commit("SET_USER_DATA", [creationDate, lastLoggedIn]);

        await state.mapService.setLastLoggedIn();

        const notification = await state.mapService.getNotification();
        commit("SET_NOTIFICATION", notification);

        await dispatch("getMaps");
      }
    }
  },
  async getMaps({ commit, state }: { commit: Commit; state: RootState }) {
    if (state.mapService) {
      commit("GETTING_MAPS");
      const allMaps = await state.mapService.getMaps();
      commit("GETTING_MAPS_DONE", allMaps);
    }
  },
  // MAP
  async getMapData(
    { commit, state }: { commit: Commit; state: RootState },
    title: string
  ) {
    if (state.mapService) {
      commit("GETTING_MAP", title);
      const mapData = await state.mapService.getMap(title);
      commit("GETTING_MAP_DONE", mapData);
    }
  },
  async addNewMap(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    [title, icon, pin, desc, link, showIcons, markerType]: [
      string,
      string,
      string,
      string,
      Link,
      ShowIcons,
      MarkerType,

    ]
  ) {
    if (state.mapService) {
      commit("ADD_MAP");

      await state.mapService.createMap(
        title,
        icon,
        pin,
        desc,
        link,
        showIcons,
        markerType
      );

      commit("ADD_MAP_DONE");
      await dispatch("getMaps");
    }
  },
  async updateMap(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    [title, icon, pin, newTitle, desc, link, showIcons]: [
      string,
      string,
      string,
      string,
      string,
      Link,
      ShowIcons
    ]
  ) {
    if (state.mapService) {
      commit("UPDATE_MAP");
      await state.mapService.updateMap(title, icon, pin, newTitle, desc, link, showIcons);
      commit("UPDATE_MAP_DONE", newTitle.length > 0 ? newTitle : title);

      if (newTitle.length === 0) {
        await dispatch("getMapData", title);
      }
      await dispatch("getMaps");
    }
  },
  async deleteCurrMap({
    dispatch,
    commit,
    state
  }: {
    dispatch: Dispatch;
    commit: Commit;
    state: RootState;
  }) {
    if (state.mapService && state.currMap) {
      commit("DELETE_MAP", state.currMap.title);
      await state.mapService.deleteMap(state.currMap.title);
      commit("DELETE_MAP_DONE", state.currMap.title);

      await dispatch("getMaps");
    }
  },
  async deleteMapByTitle(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    title: string
  ) {
    if (state.mapService) {
      commit("DELETE_MAP", title);
      await state.mapService.deleteMap(title);
      commit("DELETE_MAP_DONE", title);

      await dispatch("getMaps");
    }
  },
  async publishMap(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    title: string
  ) {
    if (state.mapService) {
      commit("PUBLISH_MAP");

      await state.mapService.publishMap(title);
      await dispatch("getMapData", title);

      commit("PUBLISH_MAP_DONE", title);
    }
  },
  async importData(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    [title, file, override]: [string, File, boolean]
  ) {
    if (state.mapService) {
      commit("IMPORT");
      try {
        const importResponse = await state.mapService.importData(
          title,
          file,
          override
        );
        await dispatch("getMapData", title);
        commit("IMPORT_DONE", {
          fileName: file.name,
          error: false,
          importResponse: importResponse
        });
      } catch (e) {
        // eslint-disable-next-line
        console.error(e);
        commit("IMPORT_DONE", { fileName: file.name, error: true });
      }
    }
  },
  // MARKER
  async addMapRecord(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    marker: Marker
  ) {
    if (state.mapService && state.currMap) {
      commit("ADD_MARKER");
      await state.mapService.addMarker(
        state.currMap.title,
        state.currMap.id,
        marker
      );

      commit("ADD_MARKER_DONE", marker.name);
      await dispatch("getMapData", state.currMap.title);
    }
  },
  async updateMarker(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    marker: Marker
  ) {
    if (state.mapService && state.currMap) {
      commit("UPDATE_MARKER");
      await state.mapService.updateMarker(
        state.currMap.title,
        state.currMap.id,
        marker
      );

      commit("UPDATE_MARKER_DONE", marker.name);
      await dispatch("getMapData", state.currMap.title);
    }
  },
  async deleteMarker(
    {
      dispatch,
      commit,
      state
    }: { dispatch: Dispatch; commit: Commit; state: RootState },
    id: string
  ) {
    if (state.mapService && state.currMap) {
      commit("DELETE_MARKER");
      await state.mapService.deleteMarker(
        state.currMap.title,
        state.currMap.id,
        id
      );

      commit("DELETE_MARKER_DONE");
      await dispatch("getMapData", state.currMap.title);
    }
  }
};
